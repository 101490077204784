.new-product label {
  display: none;
}
.new-product input,
.new-product select {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: solid 2px #85b4aa;
  background-color: #f4efef;
  font-family: "Umma";
  font-size: 16px;
}
.new-product input::placeholder {
  font-family: "Umma";
  font-size: 16px;
  font-style: italic;
}
.new-product input:focus,
.new-product input:active,
.new-product select:focus,
.new-product select:active {
  outline: none;
  border-color: #dfa06e;
}
.new-product .myDropzone {
  align-self: center;
  margin-bottom: 15px;
  background-color: #f4efef;
}
.new-product .select-container input {
  width: 300px;
  box-sizing: border-box;
}
.new-product .select-container .btn-validate-category {
  top: 18px;
  right: 8px;
}
