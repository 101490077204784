/* Other rules */

/* Font */
@font-face {
  font-family: "Unna";
  src: local("Unna"), url("./assets/googleFont/Unna/Unna-Regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "Unna";
  src: local("Unna"), url("./assets/googleFont/Unna/Unna-Italic.ttf");
  font-style: italic;
}
@font-face {
  font-family: "Unna";
  src: local("Unna"), url("./assets/googleFont/Unna/Unna-Bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "Unna";
  src: local("Unna"), url("./assets/googleFont/Unna/Unna-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Dancing Script";
  src: local("Dancing Script"),
    url("./assets/googleFont/Dancing_Script/DancingScript-VariableFont_wght.ttf");
}
@font-face {
  font-family: "Lobster Two";
  src: local("Lobster Two"),
    url("./assets/googleFont/Lobster_Two/LobsterTwo-Regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "Lobster Two";
  src: local("Lobster Two"),
    url("./assets/googleFont/Lobster_Two/LobsterTwo-Italic.ttf");
  font-style: italic;
}
@font-face {
  font-family: "Lobster Two";
  src: local("Lobster Two"),
    url("./assets/googleFont/Lobster_Two/LobsterTwo-Bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "Lobster Two";
  src: local("Lobster Two"),
    url("./assets/googleFont/Lobster_Two/LobsterTwo-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}

body {
  color: #2f4858;
  font-family: "Unna";
}

.container {
  max-width: 1350px;
  margin: 0 auto;
}
.pages {
  margin-top: 175px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.product-list::-webkit-scrollbar,
.on-display-products-list::-webkit-scrollbar,
.product-array::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.product-list,
.on-display-products-list,
.product-array {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Link */
a {
  color: #618f92;
  text-decoration: none;
  font-size: 20px;
}
a:hover {
  font-weight: bold;
  color: #dfa06e;
}

/* Titles */
h1,
h2,
h3,
h4 {
  /* font-family: "Dancing Script"; */
  font-family: "Lobster Two";
  /* font-family: "Caveat"; */
}
h1 {
  font-size: 48px;
  font-weight: bold;
  color: #2f4858;
}
h2 {
  font-size: 40px;
  font-weight: bold;
  font-style: italic;
  margin: 10px 0;
}
h3 {
  font-size: 22px;
  font-weight: bold;
  margin: 20px 0;
}

/* Form */
/* Product form */
.new-product {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

/* Signup Login */
#forgotten-password,
#signup,
#login,
#reini-modal {
  max-width: 350px;
}

.signup-form,
.login-form,
.forgotten-password,
.rest-pass-form {
  display: flex;
  flex-direction: column;
}
.signup-title,
.login-title,
.rest-pass-title {
  text-align: center;
  margin-bottom: 1rem;
}
.checkbox-signup {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-bottom: 10px;
}
/* CheckBox signup */
.checkbox-signup > [type="checkbox"],
.transport-container label > [type="radio"] {
  width: 1.5rem;
  height: 1.5rem;
  color: #618f92;
  vertical-align: middle;
  -webkit-appearance: none;
  background: none;
  border: 0;
  outline: 0;
  flex-grow: 0;
  border-radius: 50%;
  background-color: #b1d9c1;
  transition: background 300ms;
  cursor: pointer;
}
.checkbox-signup > [type="checkbox"]::before,
.transport-container label > [type="radio"]::before {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #618f92;
}
.checkbox-signup > [type="checkbox"]:checked,
.transport-container label > [type="radio"]:checked {
  background-color: currentcolor;
}
.checkbox-signup > [type="checkbox"]:checked::before,
.transport-container label > [type="radio"]:checked::before {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}

.toggle-modal {
  margin-top: 10px;
  color: #2f4858;
  cursor: pointer;
  text-align: center;
}
.toggle-modal:hover {
  color: #dfa06e;
  text-decoration: underline;
}
.forgotten-password {
  text-align: center;
  margin: 15px;
}
.forgotten-password > span:hover {
  color: #dfa06e;
  cursor: pointer;
  text-decoration: underline;
}
.forgotten-password > p {
  text-align: center;
  margin-bottom: 10px;
}
/* buttons */
.btn {
  background-color: #85b4aa;
  color: #e4fdd9;
  border: none;
  padding: 10px 15px;
  border-radius: 50px;
  cursor: pointer;
  font-family: "Unna";
  font-size: 18px;
  transition: background 300ms, color 300ms;
}
.btn:hover {
  background-color: #b1d9c1;
  color: #996d4b;
}
.btn-login {
  margin-top: 10px;
  white-space: nowrap;
}
.btn-modal {
  align-self: center;
  width: 150px;
}
.btn-close-modal-container {
  min-width: 350px;
  display: flex;
  justify-content: flex-end;
}
.btn-close-modal {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-backOffice {
  color: #f5f3bb;
  background-color: #dfa06e;
}
.btn-backOffice:hover {
  color: #85b4aa;
  background-color: #f5f3bb;
}
.btn-new-product {
  width: 150px;
  align-self: center;
}
.btn-cancel {
  background-color: #b1d9c1;
  color: #2f4858;
  margin-right: 30px;
}
.btn-cancel:hover {
  background-color: #dfa06e;
  color: #b1d9c1;
}

/* Product box */
.filters-product {
  background-color: #e4fdd9;
  padding: 15px;
  border-radius: 10px;
  margin: 10px 0;
}
.filters-product > input {
  padding: 5px;
  font-size: 18px;
  border-radius: 5px;
  border: solid 1px;
}
.filters-product > input:active,
.filters-product > input:focus {
  outline: solid 1px #dfa06e;
  border-radius: 5px;
  border: none;
}
.product-array {
  min-width: 500px;
  margin: 0 auto;
  max-height: 250px;
  overflow-x: hidden;
}
.label-product {
  text-align: center;
  font-weight: bold;
  font-style: italic;
}
.product-box {
  display: flex;
  border: solid 2px #618f92;
  border-radius: 5px;
  margin-bottom: 10px;
}
.product-name,
.product-quantity,
.product-category,
.product-edit,
.product-delete {
  padding: 10px;
  border-left: solid 1px #618f92;
  display: flex;
  align-items: center;
  text-align: center;
}
.product-name,
.product-categories,
.product-quantity {
  min-width: 60px;
}

.product-name {
  flex: 1.5;
  background-color: #f5f3bb;
  border-left: none;
}
.product-category {
  flex: 1.5;
  background-color: #e4fdd9;
}
.product-quantity {
  flex: 1;
  background-color: #f5f3bb;
  display: flex;
  justify-content: space-around;
}
.product-quantity > input {
  width: 50px;
}
.icon-handle-product:hover {
  color: #dfa06e;
  cursor: pointer;
}
/* Back Office */
.backoffice-container {
  display: flex;
  /* height: 100vh; */
}
.backoffice-title {
  font-family: "Dancing Script";
  font-size: 64px;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 200px;
}
.backoffice-active-work {
  border: 2px solid #b1d9c1;
  border-radius: 15px;
  background-color: #e4fdd9;
  padding: 10px;
  margin: 20px 20px 0 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: flex-start;
}
.ul {
  list-style: circle;
  margin-left: 50px;
}

.backoffice-li {
  font-size: 18px;
  cursor: pointer;
}
.backoffice-li:hover {
  color: #dfa06e;
}

.backoffice-nav {
  background-color: #b1d9c1;
  min-width: 300px;
  min-height: 400px;
  border-radius: 10px;
  margin-left: 15px;
  padding: 10px;
  box-sizing: border-box;
}
.backoffice-nav h2 {
  text-align: center;
}
.backoffice-nav h3 {
  margin-left: 10px;
}

.handle-home-picture {
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
}
.handle-home-picture > label {
  margin: 0 15px;
  min-width: 100px;
}
.handle-home-picture > button {
  width: 100px;
  margin-left: 15px;
  align-self: center;
}
.handle-home-picture > .myDropzone {
  width: 200px;
}

/* loader */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px;
}
.loading {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Alert */
.container-alert {
  min-height: 70px;
  border-radius: 10px;
  padding: 10px;
  font-size: 18px;
}
.close-alert {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.btn-alert {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* product update */
.no-label {
  display: none;
}
.update-product {
  border: solid 1px;
  border-radius: 5px;
  padding: 5px;
}

/* Map Contact */
.wrapper-container {
  position: relative;
  width: 80%;
  height: 500px;
  margin: 50px auto 0 auto;
}
.map-container {
  width: 100%;
  height: 100%;
}
.clicked-coord-label {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background: white;
  border-radius: 5px;
  padding: 3px;
}
.ol-zoom {
  padding: 25px;
  display: inline-flex;
  flex-direction: column;
}
.ol-zoom-out,
.ol-zoom-in {
  border: none;
  padding: 0;
  margin-bottom: 10px;
  border-radius: 10px;
}

#attribution {
  position: absolute;
  left: 10px;
  bottom: 10px;
  background-color: white;
  border-radius: 5px;
  padding: 3px;
}
#attribution:hover {
  transform: scale(1);
  font-weight: 400;
  color: #2f4858;
}

.adress-container-map {
  display: flex;
  justify-content: center;
  padding-top: 50px;
}
.adress-map {
  line-height: 20px;
}
.adress-map > strong {
  font-weight: bold;
  margin-left: -90px;
  line-height: 30px;
  font-size: 20px;
}

/* Popup */
.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: auto;
  white-space: nowrap;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
  background-color: white;
  margin: 0;
  padding: 0;
  border: none;
  box-shadow: none;
  color: #dfa06e;
}
.ol-popup-closer:after {
  content: "✖";
}
.ol-popup-closer:hover {
  cursor: pointer;
  color: #618f92;
  background-color: white;
}
#popup-content {
  font-weight: bold;
}

/* Form contact */
.form-contact-container {
  text-align: center;
}
.form-contact-container > h3 {
  margin-top: 50px;
  font-size: 24px;
}
.form-contact-container > h4 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}
.icon-form-contact {
  color: #618f92;
  width: 20px;
  height: 20px;
  margin-left: 5px;
}
.form-container {
  background-color: #e4fdd9;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 50px auto;
  text-align: center;
  padding: 20px;
}
.form-contact-label,
.form-contact-input,
.form-contact-area {
  margin: 10px auto 0 auto;
  padding: 10px;
  width: 80%;
  text-align: left;
  font-size: 18px;
}
.form-contact-label {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: solid 1px #618f92;
}
.form-contact-input,
.form-contact-area {
  background-color: #e4fdd9;
  border: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  outline: none;
}
.form-contact-input:hover,
.form-contact-input:focus,
.form-contact-area:hover,
.form-contact-area:focus {
  background-color: #b1d9c1;
}
.form-contact-area {
  font-family: inherit;
  resize: none;
  font-size: 18px;
}
.form-contact-error {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #df5050;
}
.btn-form-contact {
  max-width: 250px;
  align-self: center;
  margin-top: 20px;
}

/* Alert by product */
.alert-by-product-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.alert-by-product-item {
  display: flex;
  align-items: center;
  border: 1px solid #b1d9c1;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 0 20px;
  box-sizing: border-box;
  background-color: white;
}
.alert-by-product-title {
  text-align: center;
  margin-right: 15px;
  font-size: 28px;
}
.alert-by-product-img-container {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  margin-right: 15px;
}
.alert-by-product-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.alert-by-product-nav {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Promotions */

.promotions-form {
  display: flex;
  flex-direction: column;
}

/* Products onDisplay backoffice */
.product-on-display-container {
  border: solid 2px #b1d9c1;
  border-radius: 15px;
  padding: 10px;
  max-width: 400px;
  height: 250px;
}
.on-display-products-list {
  background-color: #b1d9c1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow-x: hidden;
  height: 135px;
}
.tab-products-list,
.label-tab-products-list {
  display: flex;
  justify-content: center;
  align-items: stretch;
  text-align: center;
}
.tab-products-list span,
.label-tab-products-list span,
.tab-products-list label {
  flex: 1;
  padding-top: 5px;
  padding-bottom: 5px;
}
.label-tab-products-list span {
  border-bottom: 1px solid #e4fdd9;
  background-color: #b1d9c1;
}
.label-tab-products-list span:first-child {
  border-top-left-radius: 10px;
}
.label-tab-products-list span:last-child {
  border-top-right-radius: 10px;
}
.tab-products-list label {
  box-sizing: border-box;
  margin-bottom: 0px;
}
.tab-products-list span {
  border-right: 1px solid #e4fdd9;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .pages {
    margin-top: 125px;
  }
}
@media (max-width: 540px) {
  .alert-by-product-item {
    padding: 5px 10px;
    margin: 0 5px;
  }
  .alert-by-product-title {
    text-align: center;
    margin-right: 10px;
    font-size: 16px;
  }
  .alert-by-product-img-container {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  .alert-by-product-nav {
    margin-top: 10px;
  }
  .alert-by-product-item .scroll-container,
  .alert-by-product-item .scroll-ul {
    width: 100px;
  }
  .alert-by-product-item .scroll-btn-close,
  .alert-by-product-item .scroll-btn-open,
  .alert-by-product-item .scroll-li {
    font-size: 14px;
  }
  .alert-by-product-nav .btn {
    font-size: 14px;
    padding: 5px 10px;
  }
}
@media (max-width: 400px) {
  #forgotten-password,
  #signup,
  #login,
  #reini-modal {
    max-width: 300px;
  }
}
