.ordered-table {
  width: 100%;
}
.update-order-container h2 {
  text-align: center;
  margin-bottom: 40px;
}
.ordered-table th {
  font-size: 24px;
  font-family: "Lobster Two";
  font-style: italic;
  outline: solid 1px #618f92;
  border-radius: 15px;
  padding: 20px;
}
.ordered-table tr {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.ordered-table .title-tr td {
  outline: solid 1px #618f92;
  background-color: #b1d9c1;
  border-radius: 5px;
  font-weight: bold;
}
.ordered-table th {
  flex: 1;
  background-color: #f0e1a9;
  margin: 10px 10px;
}
.ordered-table tbody th {
  background-color: #e4fdd9;
}
.ordered-table td {
  padding: 10px;
  flex: 1;
  margin: 3px;
}
.ordered-table td .icon-openning {
  flex: 0.3;
  padding: 0;
}

.ordered-table .table-scroll {
  display: flex;
  justify-content: center;
}
.ordered-table .table-scroll .scroll-container,
.ordered-table .table-scroll .scroll-ul {
  width: 180px;
}
.ordered-table .table-scroll .scroll-li:hover {
  background-color: #b1d9c1;
  border-radius: 10px;
}
.ordered-table .table-scroll .icon-scroll {
  top: 15px;
  right: 5px;
}
.title-tr .icon-openning {
  display: flex;
  flex-direction: column;
  flex: 0.3;
  padding: 0;
}
.order-no-content {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.order-no-content-container {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.order-no-content-container td {
  margin: 10px 0;
  background-color: #e4fdd9;
  border-radius: 15px;
}
.order-no-content span {
  font-weight: bold;
  margin-left: 0.3em;
}
.ordered-table td img {
  width: 100%;
}
.ordered-table .order-table-container {
  border: 1px solid #f0e1a9;
}
.ordered-table .icon-caret-container {
  flex: 0.1;
}
.order-table-container .order-total {
  padding: 0;
  font-size: 18px;
  text-align: end;
}
.order-table-container .order-total span {
  color: #618f92;
  font-weight: bold;
  margin-left: 0.5em;
}

@media (max-width: 670px) {
  .update-order-container {
    width: 100%;
    padding: 10px 0;
  }
  .ordered-table {
    outline: none;
  }
  .title-tr,
  .order-table-container {
    font-size: 16px;
  }
  .ordered-table tr {
    padding: 0;
    margin: 0;
  }
  .ordered-table td {
    padding: 5px;
    margin: 3px;
  }
  .order-table-container .order-total {
    font-size: 16px;
  }
}
@media (max-width: 520px) {
  .ordered-table th,
  .ordered-table .scroll-btn-close,
  .ordered-table .scroll-btn-open,
  .ordered-table .scroll-li {
    font-size: 18px;
  }
  .title-tr,
  .order-table-container {
    font-size: 12px;
  }
  .order-table-container .order-total {
    font-size: 14px;
  }
}
@media (max-width: 430px) {
  .update-order-container h2 {
    font-size: 28px;
  }
  .ordered-table th,
  .ordered-table .scroll-btn-close,
  .ordered-table .scroll-btn-open,
  .ordered-table .scroll-li {
    font-size: 14px;
  }
  .title-tr,
  .order-table-container {
    font-size: 10px;
  }
  .ordered-table .table-scroll .icon-scroll {
    top: 8px;
    font-size: 18px;
  }
  .ordered-table .table-delivery {
    display: none;
  }
  .order-table-container .order-total {
    font-size: 12px;
  }
}
