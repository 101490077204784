.products {
  width: 600px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #b1d9c1, transparent);
  padding: 15px;
  box-sizing: border-box;
  border-radius: 15px;
  margin: 0 8px 0 8px;
  flex-shrink: 0;
}
.info-col-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}
.product-nav {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.info-col-2 {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.info-col-2 > h3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  font-weight: bold;
  font-family: "dancing Script";
  text-align: center;
  margin: 0 0;
}
.info-col-2 > p {
  height: 165px;
  min-width: 100%;
  background: linear-gradient(135deg, transparent, #e4fdd9);
  padding: 15px;
  box-sizing: border-box;
  margin: 10px 0;
  border-radius: 15px;
  font-size: 20px;
  text-indent: 20px;
}
.image-product {
  height: 220px;
  max-width: 280px;
  position: relative;
}
.image-product > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.icon-info-product-left,
.icon-info-product-right {
  z-index: 2;
  font-size: 40px;
  position: absolute;
  top: 220px;
}
.icon-info-product-left {
  left: 15px;
}
.icon-info-product-right {
  right: 15px;
}
.icon-promotion {
  position: absolute;
  right: -2px;
  top: 26px;
  color: #dfa06e;
  background-color: #f5f3bb;
  border-radius: 3px;
  transform: rotate(45deg);
  padding: 3px;
}
.icon-promotion span {
  margin-left: 5px;
  position: relative;
}

.product-price {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 28px;
  justify-content: center;
  margin: 15px 0;
}
.product-old-price {
  font-style: italic;
  text-decoration: line-through;
  color: #dfa06e;
  margin-right: 15px;
}
.product-categories {
  position: relative;
}
.product-categories h2 {
  text-align: center;
  margin: 40px 0;
}
.product-list {
  width: 91%;
  margin: 0 auto;
  display: flex;
  overflow-x: scroll;
}
@media (max-width: 690px) {
  .product-categories h2 {
    font-size: 26px;
  }
  .products {
    width: 400px;
    height: 220px;
    padding: 10px;
  }
  .icon-info-product-left,
  .icon-info-product-right {
    top: 160px;
  }
  .image-product {
    height: 146px;
    max-width: 180px;
  }
  .product-price {
    font-size: 20px;
    margin: 5px 0;
  }
  .info-col-2 > h3 {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 30px;
  }
  .info-col-2 > p {
    height: 100px;
    font-size: 16px;
    text-indent: 15px;
    overflow: hidden;
  }
  .info-col-2 a {
    font-size: 16px;
  }
  .product-nav .btn {
    font-size: 14px;
    padding: 5px;
    margin: 0;
  }
}

@media (max-width: 470px) {
  .product-categories h2 {
    font-size: 20px;
  }
  .products {
    width: 300px;
    height: 165px;
    padding: 8px;
  }
  .icon-info-product-left,
  .icon-info-product-right {
    top: 120px;
    font-size: 32px;
  }
  .image-product {
    height: 110px;
    max-width: 135px;
  }
  .product-price {
    font-size: 16px;
    margin: 0;
  }
  .info-col-2 > h3 {
    font-size: 20px;
  }
  .info-col-2 > p {
    height: 85px;
    font-size: 12px;
    margin: 0;
    text-indent: 10px;
  }
  .info-col-2 a {
    font-size: 14px;
  }
  .product-nav .btn {
    font-size: 12px;
    padding: 1px;
  }
  .product-nav button:nth-child(2) {
    margin-left: 4px;
  }
}
