.btn-modal-modial {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
#mondial-modal {
  max-width: 550px;
}
@media (max-width: 620px) {
  #mondial-modal {
    max-width: 300px;
  }
}
