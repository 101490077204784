.perfume-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#new-perfume {
  border: solid 2px #85b4aa;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
}
#new-perfume input {
  padding: 10px;
  margin-right: 15px;
  border: solid 1px #85b4aa;
  border-radius: 5px;
  font-size: 16px;
}
#new-perfume input:focus-visible {
  outline-color: #f0e1a9;
}
#new-perfume input::placeholder {
  font-style: italic;
}

#perfume th {
  background-color: #f0e1a9;
  font-size: 18px;
  font-weight: bold;
}
#perfume th,
#perfume td {
  padding: 15px;
}
#perfume tr:not(:last-child) {
  border-bottom: solid 1px #618f92;
}
#perfume td:first-child {
  border-right: solid 1px #618f92;
}
.icon-delete-perfume {
  transition: color 300ms;
  cursor: pointer;
}
.icon-delete-perfume:hover {
  color: #dfa06e;
}
