.pdf-container {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.document-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}
.document-container p {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.icon-arrow-pdf {
  background-color: #b1d9c1;
  padding: 3px 8px;
  border: solid 1px #618f92;
  border-radius: 20px;
  margin: 0 10px;
  cursor: pointer;
  transition: background 300ms;
}
.icon-arrow-pdf:hover {
  background-color: #85b4aa;
}
.user-action {
  margin-bottom: 20px;
}
.user-action a:hover {
  font-weight: normal;
}
.user-action button:first-child {
  margin-right: 15px;
}
.user-action .icon-user-action {
  margin-right: 10px;
}
@media (max-width: 400px) {
  .user-action button {
    font-size: 14px;
  }
}
