/* Hide scrollbar for Chrome, Safari and Opera */
.search-bar-container::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.search-bar-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.search-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: sticky;
  margin: 150px auto 0 auto;
  top: 180px;
  z-index: 3;
}
.search-bar-container {
  width: 100%;
  height: 60px;
  position: relative;
  background-color: #b1d9c1;
  padding: 10px 20px;
  border: solid 2px #85b4aa;
  border-radius: 15px;
  box-sizing: border-box;
  font-size: 20px;
  font-family: "Lobster Two";
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: width 1s, height 1s;
}
.overflow-hidden {
  overflow: hidden;
}
.close-search-container {
  width: 50px;
  height: 50px;
}

.search-label {
  display: flex;
  align-items: center;
  position: relative;
}
.search-input {
  padding: 8px;
  margin: 0 10px;
  outline-color: #dfa06e;
  font-size: 18px;
  border-style: none;
  border: solid 2px #85b4aa;
  border-radius: 5px;
}
.icon-search-bar {
  position: absolute;
  right: 14px;
  font-size: 24px;
  cursor: pointer;
}
.icon-search-bar:hover {
  color: #dfa06e;
}
.search-bar-container .scroll-container {
  font-family: "Unna";
}
.search-category {
  display: flex;
  align-items: center;
}
.search-sort {
  margin-left: 10px;
  display: flex;
  align-items: center;
}
.switch {
  margin-left: 10px;
}
.search-range {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.search-icon-bar {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #456b76;
  background-color: #b1d9c1;
  border-radius: 8px;
  border: solid 2px #85b4aa;
  cursor: pointer;
}
.search-icon-bar:hover {
  color: #dfa06e;
}
.search-icon-arrow {
  background-color: #85b4aa;
  border-radius: 5px;
  border: solid 1px #456b76;
  margin-left: 20px;
  font-size: 26px;
  padding: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hidden {
  display: none;
}

@media (max-width: 1090px) {
  .search-bar-container {
    font-size: 18px;
  }
  .search-bar-container .scroll-container {
    max-width: 150px;
  }
  .search-bar-container .scroll-btn-open,
  .search-bar-container .scroll-btn-close {
    font-size: 18px;
  }
  .search-bar-container .scroll-ul {
    max-width: 150px;
    z-index: 3;
  }
  .search-bar-container .icon-scroll {
    right: 10px;
    top: 9px;
    font-size: 20px;
  }
  .search-bar-container .track {
    max-width: 100px;
  }
  .search-bar-container .search-input {
    max-width: 150px;
  }
}
@media (max-width: 960px) {
  .search-bar-container span {
    display: none;
  }
}
@media (max-width: 720px) {
  .search-container {
    margin-bottom: 0;
  }

  .search-bar-container {
    width: 200px;
    height: 200px;
    flex-direction: column;
    justify-content: flex-start;
    overflow: scroll;
    margin-bottom: 0;
  }

  .search-bar-container div,
  .search-bar-container label {
    flex-direction: column;
  }
  .search-bar-container span {
    display: flex;
    margin: 10px 0;
  }
  .icon-search-bar {
    top: 47px;
  }
  .search-bar-container .switch,
  .search-bar-container .slider,
  .search-bar-container .scroll-container {
    margin: 0;
  }
  .close-search-container {
    height: 45px;
    width: 45px;
  }
  .search-icon-arrow {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
@media (max-width: 600px) {
  .search-container {
    top: 130px;
    margin-top: 125px;
  }
}
