/* Global */
.label-modal,
.input-modal {
  align-self: center;
  min-width: 300px;
  background-color: #85b4aa;
  padding: 5px;
  border-radius: 5px;
  border: solid 1px #618f92;
}
/* Input */
.input-modal {
  margin: 5px 0 10px 0;
  outline: none;
  background-color: #b1d9c1;
}
.input-modal:active,
.input-modal:focus {
  border: solid 2px #dfa06e;
}
.input-select {
  align-self: center;
}
/* Password */
.password {
  align-self: center;
  position: relative;
}
.icon-form {
  position: absolute;
  top: 11px;
  right: 10px;
}
/* Checked user */
.checked-user {
  align-self: center;
  position: relative;
}
.icon-check {
  position: absolute;
  top: 11px;
  right: 10px;
}

/* Error */
.error {
  margin: -7px auto 5px auto;
  min-width: 300px;
  color: #c53030;
  text-align: center;
  font-weight: bold;
}

/* Drop-Zone */
.myDropzone {
  background-color: #f5f3bb;
  border: solid 2px #618f92;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 15px;
  /* align-self: center; */
  margin: 20px 0;
  cursor: pointer;
}
.myDropzone:hover {
  background-color: #dae5b1;
}
.previous-img {
  height: 100%;
  width: 100%;
  position: relative;
}
.previous-img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.btn-img {
  position: absolute;
  top: 3px;
  right: 0px;
  height: 10px;
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  background-color: #85b4aa;
}

/* select */
.select-container {
  position: relative;
  display: flex;
  align-items: center;
}
.btn-validate-category {
  cursor: pointer;
  position: absolute;
  top: 11px;
  right: 5px;
  font-weight: bold;
  color: #618f92;
}
.btn-validate-category:hover {
  color: #dfa06e;
  font-weight: bold;
}
