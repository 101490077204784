.footer-container {
  margin-top: 20px;
  background-color: #e4fdd9;
  padding: 30px;
  display: flex;
  justify-content: space-around;
}
.legal-notice,
.dev-container,
.partners {
  margin: 0 20px 20px 20px;
}
.legal-notice,
.dev-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.legal-notice h4,
.dev-container h4 {
  margin-bottom: 5px;
}
.legal-notice a {
  font-size: 16px;
  font-style: italic;
  margin: 5px 0;
}
.legal-notice a:hover {
  font-weight: normal;
}
.dev-container {
  margin: 0 20px;
  max-width: 150px;
}
.dev-container span {
  margin: 5px 0;
}
.dev-container .link-dev img {
  width: 35px;
}

.partners h4 {
  margin-bottom: 10px;
  text-align: center;
}
.font-awesome,
.netlify,
.google-font {
  margin-bottom: 5px;
}

.font-awesome a,
.google-font a,
.netlify a {
  color: #74c0fc;
  font-size: "Umma", cursive;
  display: flex;
  align-items: center;
}
.icon-partners {
  font-size: 24px;
  margin-right: 5px;
}
.google-font img,
.netlify img {
  width: 24px;
  margin-right: 5px;
}
@media (max-width: 670px) {
  .footer-container {
    display: flex;
    flex-wrap: wrap;
  }
}
