/* Home */
.head-img {
  max-width: 800px;
  display: flex;
  justify-content: center;
  margin: 50px auto;
  position: relative;
}
.img-fond {
  width: 100%;
  border-radius: 20px;
  object-fit: contain;
}
.img-fond-text {
  width: 100%;
  top: 50px;
  position: absolute;
  text-align: center;
  font-size: 5rem;
  font-family: "Dancing Script";
  color: #dfa06e;
  -webkit-text-stroke: 0.7px #e4fdd9;
}
/* Carousel */
.carousel-products {
  width: 100%;
  display: flex;
}
.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.carousel-container h2 {
  margin-bottom: 25px;
}
.carousel-items-container {
  height: 100%;
  display: flex;
  align-items: center;
}
.carousel-items-container img {
  border-radius: 5px;
}
.carousel-legend {
  font-size: 18px;
}
.home-col-1,
.home-col-2 {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  border: 2px solid #85b4aa;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 25px;
  box-shadow: -1px -1px 10px 1px;
  margin-left: 10px;
}
.carousel-box {
  display: flex;
}
.home-col-1 h2,
.home-col-2 h2 {
  text-align: center;
  margin-bottom: 50px;
  display: block;
  background: linear-gradient(30deg, #85b4aa 20%, #b1d9c1, #e4fdd9);
  box-shadow: 10px 10px 5px 1px;
  padding: 30px;
  margin-top: 0;
  border-radius: 8px;
  color: #456b76;
}
.home-col-2 h2 {
  background: linear-gradient(30deg, #dfa06e, #e8c18c, #f0e1a9 95%);
  color: #996d4b;
}
.home-col-1 p,
.home-col-2 p {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  font-size: 22px;
  border-radius: 5px;
}
.home-col-2 {
  margin-top: 300px;
  border-color: #996d4b;
  box-shadow: -1px -1px 5px 1px #996d4b;
}
.home-col-1 .legend {
  border: 2px solid #85b4aa;
}
.home-col-2 .legend {
  border: 2px solid #996d4b;
}
.legend span {
  margin: 0 10px;
  font-size: 18px;
}
.carousel-old-price {
  color: #dfa06e;
  font-style: italic;
  text-decoration: line-through;
}

.title-description,
.title-helper {
  font-family: "Dancing script";
  font-size: 74px;
  font-style: italic;
  margin: 40px 0;
  color: #996d4b;
}
.description,
.helper {
  max-width: 600px;
  padding: 15px;
  margin: 0 auto;
}
.description p {
  font-size: 20px;
  line-height: 24px;
  tab-size: 4;
}
.title-helper-container {
  display: flex;
  align-items: center;
}
.icon-helper {
  margin-right: 30px;
  font-size: 60px;
  color: #85b4aa;
}
.helper ul h4 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}
.helper ul h4 span {
  color: #dfa06e;
  font-weight: bold;
  font-style: italic;
}
.helper ul p {
  margin-bottom: 15px;
  text-indent: 20px;
  font-weight: bold;
}
.helper ul {
  font-size: 20px;
}
.helper li {
  margin-bottom: 15px;
}
.helper li::before {
  content: "◦";
  color: #85b4aa;
  font-size: 16px;
  margin-right: 5px;
}

@media (max-width: 1190px) {
  .carousel-box {
    flex-direction: column;
  }
}
@media (max-width: 900px) {
  .carousel-products {
    flex-direction: column;
  }
  .home-col-1,
  .home-col-2 {
    margin: 0 50px;
  }
  .home-col-2 {
    margin-top: 50px;
  }
  .carousel-box {
    flex-direction: row;
  }
}
@media (max-width: 745px) {
  .carousel-box {
    flex-direction: column;
  }
  .head-img {
    margin: 0 auto 50px auto;
  }
}
@media (max-width: 440px) {
  .home-col-1 h2,
  .home-col-2 h2 {
    margin-bottom: 30px;
    padding: 20px;
    font-size: 28px;
  }
  .carousel-container .carousel .slider-wrapper {
    width: 230px;
  }
  .carousel-box p {
    font-size: 18px;
    padding: 15px;
  }
  .title-description,
  .title-helper {
    font-size: 50px;
    text-align: center;
  }
  .description p {
    font-size: 18px;
    line-height: 20px;
  }
  .icon-helper {
    margin-right: 0;
  }
}
