.profile-adress h2 {
  text-align: center;
}
.title-profile-billing,
.profile-adress-billing,
.title-profile-adress-delivery,
.title-profile-delivery,
.profile-adress-delivery,
.adress-info-container {
  display: flex;
  align-items: center;
}
.title-profile-delivery {
  text-align: center;
}
.title-profile-billing {
  margin: 15px 0;
}
.title-profile-adress-delivery {
  justify-content: space-between;
  margin: 15px 0;
}
.profile-icon-title {
  margin: 0 10px;
  font-size: 28px;
  color: #618f92;
}
.profile-adress-billing,
.profile-adress-delivery {
  justify-content: space-between;
  border-top: solid 2px #85b4aa;
  border-bottom: solid 2px #85b4aa;
  padding: 20px 0;
  margin: 0 20px;
}
.profile-adress-delivery {
  border-bottom: none;
}
.profile-adress-billing p,
.profile-adress-delivery p {
  font-weight: bold;
  flex: 1;
  text-align: center;
}
.adress-info-container {
  justify-content: space-around;
  flex: 1;
}
.adress-info,
.adress-icon-info {
  display: flex;
  flex-direction: column;
  line-height: 24px;
}
.profile-icon-handle-adress {
  font-size: 24px;
  margin-top: 10px;
  color: #e4b17d;
  cursor: pointer;
  transition: color 300ms;
}
.profile-icon-handle-adress:hover {
  color: #996d4b;
}
.title-profile-adress-delivery button,
.profile-billing button {
  border: 2px solid #618f92;
  border-radius: 50px;
  padding: 5px 10px;
  background-color: white;
  color: #618f92;
  font-size: 18px;
  transition: background 300ms;
  cursor: pointer;
}
.title-profile-adress-delivery button:hover {
  color: white;
  background-color: #618f92;
}
.profile-billing button {
  color: #e4b17d;
  border-color: #e4b17d;
}
.profile-billing button:hover {
  color: white;
  background-color: #e4b17d;
}
.profile-no-delivery {
  background-color: #e4fdd9;
  border-radius: 15px;
  padding: 25px;
  box-sizing: border-box;
  text-align: center;
}
@media (max-width: 720px) {
  .profile-adress {
    width: 100%;
  }
}
@media (max-width: 520px) {
  .profile-adress-billing,
  .profile-adress-delivery {
    flex-direction: column;
  }
  .profile-adress-billing p,
  .profile-adress-delivery p {
    margin-bottom: 20px;
  }
  .adress-icon-info {
    margin-left: 30px;
  }

  .title-profile-delivery {
    width: 100%;
  }
  .title-profile-adress-delivery {
    flex-direction: column;
  }
}
