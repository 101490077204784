.scroll-type-status .scroll-container,
.order-list-table {
  margin: 2em auto;
}
.scroll-type-status .scroll-li {
  text-align: center;
}
.backoffice-order-container h2 {
  text-align: center;
}
.order-list-table {
  overflow: visible;
}
.order-list-table tr {
  display: flex;
  justify-content: center;
}
.order-list-table tr:not(.last-order) {
  border-bottom: 1px solid #618f92;
}
.order-list-table th {
  background-color: #b1d9c1;
  width: 100%;
  padding: 1em 0;
  font-size: 1.7em;
  font-weight: bold;
  font-family: "Lobster Tow", cursive;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.order-list-table .title-list-table,
.order-list-table .title-list-article,
.order-list-table .title-list-price {
  font-size: 1.1em;
  font-weight: bold;
  background-color: #f0e1a9;
}
.order-list-table .list-article-details td,
.order-list-table .title-list-article td,
.order-list-table .title-list-price td,
.order-list-table .list-order-price td {
  flex: 1;
}
.order-list-table td {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1em;
  box-sizing: border-box;
  width: 100px;
}
.order-list-table td:nth-child(4),
.order-list-table td:nth-child(5) {
  width: 50px;
}
.order-list-table td:not(:last-child) {
  border-right: solid 1px #618f92;
}
.order-list-table .list-article-details,
.order-list-table .list-order-price {
  background-color: #f5f3bb;
}
.order-list-table .title-list-price {
  background-color: #b1d9c1;
}
.icon-edit-status,
.icon-open-detail {
  cursor: pointer;
  font-size: 18px;
  transition: color 300ms;
  color: #dfa06e;
}
.icon-edit-status:hover,
.icon-open-detail:hover {
  color: #996d4b;
}
.order-list-table .scroll-container,
.order-list-table .scroll-btn-close,
.order-list-table .scroll-btn-open,
.order-list-table .scroll-ul,
.order-list-table .scroll-li {
  width: 99px;
  background-color: #f0e1a9;
  padding: 0;
  font-size: inherit;
  border: none;
  padding: 0.5em 0;
}
.order-list-table .scroll-ul {
  left: 0;
}
.order-list-table .scroll-li {
  position: relative;
}
.order-list-table .scroll-li:hover {
  background-color: #dfa06e;
}
