.loaderSignup {
  margin: 0 auto;
}
.signup-avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signup-form label,
.login-form label {
  display: none;
}
.signup-form input,
.login-form input {
  padding: 10px;
  font-family: "Umma";
  font-size: 16px;
  border-width: 2px;
}
.signup-form input::placeholder,
.login-form input::placeholder {
  font-family: "Umma";
  font-style: italic;
}
.signup-form .icon-form,
.login-form .icon-form {
  top: 18px;
}
.advertissement {
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
}
.advertissement a {
  font-size: 14px;
}
