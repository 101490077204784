.container-article {
  max-width: 800px;
  border: solid 2px #b1d9c1;
  border-radius: 20px;
  margin: 250px auto 0 auto;
  padding: 20px;
  box-sizing: border-box;
}
.header-article {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.btn-article-container button:first-child {
  margin-right: 20px;
}
.header-article nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.image-article {
  max-width: 400px;
}
.image-article > img {
  width: 100%;
  border-radius: 15px;
}
.vote-container {
  margin: 15px 0;
  font-size: 20px;
}
.header-article > nav > p {
  text-align: center;
  font-size: 34px;
}

.info {
  display: flex;
  align-items: center;
  border-bottom: solid 1px #b1d9c1;
  padding: 10px;
  margin: 10px 0;
}
.info input,
.info label,
.info p,
.info ul,
.info span {
  flex: 1;
}
.info .anchor-link {
  margin-left: 15px;
  font-size: 16px;
}
.info .icon-anchor {
  margin-right: 5px;
}
.info > h4 {
  width: 50%;
  font-size: 20px;
  font-weight: bold;
  color: #618f92;
}
.info > p {
  font-size: 18px;
}
.info > p > span {
  font-size: 14px;
}
.info input {
  max-width: 200px;
}
.info span {
  margin-left: 15px;
}
.last-info {
  border-bottom: none;
}
.icon-vote {
  margin-left: 15px;
  font-size: 24px;
  color: #df5050;
}
.alert-perfume h3 {
  margin-top: 0;
  text-align: center;
}
.btn-alert-perfume {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}
.star-vote-container,
.average-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stars-container {
  background-color: #e4fdd9;
  border: solid 1px #618f92;
  width: 120px;
  margin-top: 10px;
}
.star-vote-container .btn-star-vote {
  padding: 10px;
  color: #86ba90;
  background-color: #456b76;
  box-shadow: 3px 3px 3px;
  margin-top: 15px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background 300ms;
}
.star-vote-container .btn-star-vote:hover {
  color: #456b76;
  background-color: #86ba90;
}
.average-container div {
  margin-top: 5px;
  font-size: 18px;
}
.average-container span {
  font-weight: bold;
  margin-right: 5px;
  margin-top: 10px;
}
.average-star-container .stars-container {
  background-color: #dfa06e;
  color: yellow;
  border-color: #996d4b;
}

@media (max-width: 750px) {
  .container-article {
    display: flex;
    flex-direction: column-reverse;
  }
  .header-article {
    justify-content: center;
    flex-direction: column;
  }
}
@media (max-width: 600px) {
  .container-article {
    margin-top: 150px;
  }
  .info-article input {
    max-width: 80px;
    font-size: 12px;
  }
  .info span {
    margin-left: 5px;
  }
  .info .anchor-link {
    font-size: 12px;
    white-space: nowrap;
  }
}
@media (max-width: 530px) {
  .container-article h4 {
    font-size: 16px;
  }
  .container-article p,
  .info-article p {
    font-size: 14px;
  }
  .container-article p span,
  .info-article span {
    font-size: 10px;
  }
}
@media (max-width: 438px) {
  .info .anchor-link {
    margin-left: 2px;
  }
  .info p {
    line-height: 15px;
  }
  .btn-article-container button:first-child {
    margin-right: 10px;
  }
}
