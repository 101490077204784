/* Basket */
.empty-basket,
.basket-no-user {
  max-width: 600px;
  height: 400px;
  margin: 250px auto 100px auto;
  padding: 20px;
  background-color: #e4fdd9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  text-align: center;
}
.btn-empty-basket {
  margin-top: 20px;
}
.empty-basket-img {
  margin-top: 20px;
  font-size: 60px;
}
.btn-no-user-container {
  margin-top: 20px;
}
.btn-no-user {
  margin-right: 10px;
}

.basket-container {
  width: 90vw;
  border-radius: 20px;
  border: solid 1px #b1d9c1;
  min-height: 150px;
  margin: 250px auto 20px auto;
  padding: 15px 15px 0 15px;
}

.basket-element {
  height: 75px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: solid 1px #b1d9c1;
}
.basket-element:first-child {
  border-bottom: none;
}
.basket-element:last-child {
  border: none;
}
.basket-element > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-overflow: ellipsis;
}
.basket-title {
  font-size: 18px;
  min-width: 100px;
  text-overflow: ellipsis;
  text-align: center;
  border: 1px solid #b1d9c1;
  border-radius: 5px;
  background-color: #e4fdd9;
  margin: 0 2px;
}

.btn-basket {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}
.info-basket {
  display: flex;
  justify-content: flex-end;
}
.info-basket span {
  margin-right: 40px;
}
.basket-element .order-number span {
  font-size: 20px;
  margin: 0 10px;
}
.basket-element .order-icon {
  cursor: pointer;
}
.basket-element .order-icon:hover {
  color: #dfa06e;
}
.basket-element button {
  background-color: transparent;
  box-shadow: none;
  color: #85b4aa;
}
.basket-element .btn:hover {
  background-color: transparent;
  color: #dfa06e;
}
@media (max-width: 800px) {
  .basket-title {
    font-size: 14px;
    min-width: 70px;
    max-height: 60px;
  }
  .basket-element div {
    margin: 0 2px;
    text-align: center;
  }
  .basket-element div:nth-child(2),
  .basket-element div:nth-child(5) {
    display: none;
  }
  .basket-element h3 {
    font-size: 18px;
  }
}
@media (max-width: 450px) {
  .basket-element div,
  .basket-element .order-number span,
  .basket-element h3,
  .btn-basket button {
    min-width: 50px;
    font-size: 14px;
  }
  .basket-element .order-number div {
    font-size: 12px;
  }
}
