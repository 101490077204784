.search-result {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.search-result .products {
  margin-bottom: 40px;
  margin-right: 30px;
}
