header {
  width: 100%;
  height: 175px;
  border-bottom: solid 2px #85b4aa;
  background-color: #e4fdd9;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 4;
}

.header-container {
  width: 80vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.logo {
  width: 170px;
  height: 170px;
  flex-shrink: 0;
  cursor: pointer;
}
.logo > img {
  width: 100%;
  height: 100%;
}
.site-name {
  max-width: 800px;
  cursor: pointer;
  text-align: center;
  font-family: "Dancing Script";
  font-style: italic;
  font-weight: bold;
  text-shadow: -1px -1px 0 #b1d9c1, 1px 1px 0 #b1d9c1, 3px 3px 4px #456b76;
  font-size: 82px;
}

.nav-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.nav-user-connected {
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
}
.nav-bar-icon-container {
  position: relative;
  box-sizing: border-box;
  padding: 5px;
  display: none;
}
.nav-bar-icon {
  font-size: 30px;
  margin-top: 5px;
  color: #dfa06e;
  cursor: pointer;
}
.nav-bar-icon:hover {
  color: #85b4aa;
}
.nav-bar-ul {
  border: solid 2px #85b4aa;
  border-radius: 10px;
  font-family: "Dancing Script";
  font-size: 24px;
  position: absolute;
  top: -20px;
  right: -50px;
}
.nav-bar-li {
  border-bottom: solid 2px #85b4aa;
  box-sizing: border-box;
  padding: 10px;
  background-color: #e4fdd9;
  text-align: center;
  cursor: pointer;
  min-width: 150px;
}
.nav-bar-li:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.nav-bar-li:last-child {
  border-bottom: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.nav-bar-li:hover {
  background-color: #85b4aa;
  color: #e4fdd9;
}
.user-info {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}
.img-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: salmon;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.btn-header-connected {
  margin-top: 10px;
}

/* Nav pages  header */
.nav-product {
  margin-top: -20px;
  width: 50vw;
  display: flex;
  justify-content: space-around;
}
.icon-nav-header {
  margin-right: 10px;
}

@media (max-width: 1070px) {
  .site-name {
    font-size: 70px;
  }
}
@media (max-width: 1000px) {
  .nav-product a {
    font-size: 16px;
  }
  .site-name {
    font-size: 64px;
  }
}

@media (max-width: 930px) {
  .nav-user {
    flex-direction: column;
  }
  .site-name {
    font-size: 48px;
  }
}
@media (max-width: 830px) {
  .site-name {
    width: 300px;
  }
  .nav-user-connected {
    flex-direction: row;
  }
  .nav-user-connected .img-avatar {
    margin-top: 15px;
  }
  .nav-user-connected button,
  .nav-user-connected p,
  .nav-product span,
  .nav-user,
  .nav-product {
    display: none;
  }
  .icon-nav-header {
    font-size: 20px;
  }
  .nav-bar-icon-container {
    display: flex;
  }
  .site-name {
    font-size: 40px;
  }
}
@media (max-width: 700px) {
  .site-name {
    font-size: 34px;
  }
  .img-avatar {
    width: 40px;
    height: 40px;
    margin-right: 0;
  }
}
@media (max-width: 600px) {
  .nav-bar-ul {
    right: -90px;
    top: 10px;
  }
  .logo {
    display: none;
  }
  .header-container {
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  header {
    width: 100%;
    height: 125px;
  }
}
@media (max-width: 420px) {
  .img-avatar {
    display: none;
  }
}
