#profile-drop-zone label {
  display: none;
}
#profile-drop-zone .myDropzone {
  background-color: #b1d9c1;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  font-size: 16px;
  display: flex;
  align-items: center;
}
#profile-drop-zone img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: cover;
}
#profile-drop-zone .btn-img {
  top: 10px;
  right: 11px;
}
.myDropzone .dropzone-icon {
  display: none;
}
.btn-submit-avatar,
.btn-cancel-avatar {
  margin-bottom: 10px;
  padding: 5px;
  border: solid 1px #85b4aa;
  color: #85b4aa;
  border-radius: 5px;
  background-color: white;
  transition: background 300ms;
  cursor: pointer;
}
.btn-submit-avatar:hover {
  background-color: #85b4aa;
  color: white;
}
.btn-cancel-avatar {
  color: #996d4b;
  border-color: #996d4b;
}
.btn-cancel-avatar:hover {
  color: white;
  background-color: #996d4b;
}
.information-profile {
  background-color: #e4fdd9;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 5px;
  min-width: 150px;
  font-size: 20px;
}
.information-profile:hover {
  background-color: #b1d9c1;
}
@media (max-width: 950px) {
  #profile-drop-zone .myDropzone {
    width: 50px;
    height: 50px;
    font-size: 26px;
  }
  #profile-drop-zone img {
    width: 50px;
    height: 50px;
    margin-top: -2px;
    margin-left: -2px;
  }
  #profile-drop-zone .btn-img {
    top: 4px;
    right: 3px;
  }
  #profile-drop-zone .btn {
    padding: 8px;
    font-size: 10px;
  }
  #profile-drop-zone .myDropzone .dropzone-text {
    display: none;
  }
  #profile-drop-zone .myDropzone .dropzone-icon {
    display: block;
  }
}
