#modal-adress-form {
  border-color: #456b76;
  border-radius: 15px;
}
#order-form {
  padding: 20px;
  font-size: 18px;
  font-weight: bold;
}
#order-form h3 {
  text-align: center;
  font-size: 26px;
  margin-bottom: 40px;
}

#order-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

#order-form label {
  display: none;
}
#order-form input {
  border: solid 2px #85b4aa;
  border-radius: 5px;
  padding: 8px;
  font-size: 16px;
  width: 150px;
  color: #456b76;
  margin-bottom: 15px;
}
#order-form input::placeholder {
  font-style: italic;
  font-size: 14px;
  color: #456b76;
}
#order-form input:focus-visible {
  outline-color: #e4b17d;
}
#order-form .error {
  min-width: 150px;
}
.btn-adress-form-1,
.btn-adress-form-2 {
  padding: 10px 20px;
  border: solid 2px #85b4aa;
  color: #85b4aa;
  border-radius: 50px;
  background-color: white;
  font-size: 18px;
  margin-top: 20px;
  transition: background 300ms;
  cursor: pointer;
}
.btn-adress-form-1 {
  margin-right: 20px;
}
.btn-adress-form-2 {
  color: #e4b17d;
  border-color: #e4b17d;
}
.btn-adress-form-1:hover {
  background-color: #85b4aa;
  color: white;
}
.btn-adress-form-2:hover {
  background-color: #e4b17d;
  color: white;
}
