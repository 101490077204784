#form-contact-information h2,
#form-contact-password h2 {
  text-align: center;
  margin-bottom: 25px;
}
#form-contact-password h2 {
  margin-bottom: 40px;
}
.account-content,
.user-civility,
.user-account-content,
.btn-profile-information,
#form-contact-password {
  display: flex;
}
.account-content {
  justify-content: space-around;
}
.btn-profile-information {
  justify-content: center;
  margin-top: 20px;
}
.btn-profile-information button {
  border: 2px solid #85b4aa;
  color: #85b4aa;
  background-color: white;
  border-radius: 50px;
  padding: 10px 25px;
  font-size: 20px;
  transition: background 300ms;
  cursor: pointer;
}
.btn-profile-information button:hover {
  background-color: #85b4aa;
  color: white;
}
.user-civility,
.user-account-content,
#form-contact-password {
  flex-direction: column;
  margin-top: 20px;
}
.user-civility {
  margin-right: 20px;
}
.account-content p {
  max-width: 200px;
  font-size: 14px;
  font-style: italic;
  text-indent: 10px;
  color: #996d4b;
}

#form-contact-information label,
#form-contact-password label {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
}
#form-contact-information input[type="text"],
#form-contact-information input[type="date"],
#form-contact-password input {
  padding: 10px;
  width: 200px;
  background-color: #e4fdd9;
  border: solid 2px #b1d9c1;
  border-radius: 5px;
  outline: none;
  margin-bottom: 15px;
  font-size: 16px;
}

#form-contact-information input:focus-visible,
#form-contact-password input:focus-visible {
  border-color: #dfa06e;
}

#form-contact-information .error,
#form-contact-password .error {
  min-width: 150px;
}
#form-contact-password {
  justify-content: center;
  text-align: center;
}
#form-contact-password input {
  margin-top: 10px;
}
#form-contact-password .icon-form {
  top: 23px;
  cursor: pointer;
}
.delete-profile {
  width: 180px;
  border: solid 2px #dfa06e;
  background-color: white;
  border-radius: 50px;
  padding: 5px 15px;
  color: #dfa06e;
  transition: background 300ms;
  font-family: "Umma";
  font-size: 16px;
  margin: 5px auto;
  cursor: pointer;
}
.delete-profile:hover {
  background-color: #dfa06e;
  color: white;
}
