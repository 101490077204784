.side-bar-profile {
  position: sticky;
  top: 175px;
  height: 100%;
}

.profile-container,
.update-profile-container,
.wellcome,
.wellcome div,
#profile-drop-zone,
.btn-container-billing {
  display: flex;
}
.profile-container,
#profile-drop-zone,
.btn-container-billing {
  justify-content: center;
}
.update-profile-container,
.wellcome,
#profile-drop-zone {
  flex-direction: column;
}
.nav-bar-profile,
.wellcome,
#profile-drop-zone,
#form-contact-information,
#form-contact-password,
.profile-adress,
.update-order-container {
  border: solid 2px #85b4aa;
  border-radius: 10px;
  padding: 5px;
  margin: 10px 5px;
  align-items: center;
  font-size: 20px;
}
#form-contact-information,
#form-contact-password,
.profile-adress,
.update-order-container {
  width: 700px;
  padding: 30px;
  box-sizing: border-box;
}
.wellcome img,
.wellcome .no-avatar-profile {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #ecd19b;
  object-fit: cover;
  margin-top: 15px;
}
.wellcome .no-avatar-profile {
  justify-content: center;
  align-items: center;
  background-color: #f0e1a9;
  font-size: 30px;
  font-weight: bold;
}

.wellcome p {
  padding: 5px;
}
.wellcome p:first-child {
  font-weight: bold;
}
.wellcome p:last-child {
  font-style: italic;
}
.wellcome .avatar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon-avatar-profile {
  margin-top: 10px;
  cursor: pointer;
  color: #996d4b;
  transition: color 300ms;
  font-size: 12px;
  align-items: center;
}
.icon-avatar-profile:hover {
  color: #e8c18c;
}
.icon-avatar-profile .icon-profile {
  font-size: 20px;
  margin-right: 10px;
}
@media (max-width: 950px) {
  .profile-container {
    flex-direction: column;
  }
  .side-bar-profile {
    display: flex;
    align-items: center;
    position: static;
  }
  .nav-bar-profile {
    display: flex;
    flex: 1;
    justify-content: space-around;
    margin: 0;
    padding: 10px;
  }
  .nav-bar-profile .information-profile {
    margin-bottom: 0;
    text-align: center;
    padding: 10px 15px;
  }
  .nav-bar-profile .information-profile:not(:last-child) {
    margin-right: 15px;
  }
  .wellcome {
    flex-direction: row;
  }
  .wellcome .user-hello {
    font-size: 24px;
    display: flex;
    align-items: center;
  }
  .wellcome img,
  .wellcome .no-avatar-profile {
    width: 50px;
    height: 50px;
    margin-top: 0;
  }
  .wellcome .avatar-container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .wellcome .avatar-container .icon-avatar-profile {
    margin-top: 0;
    margin-left: 10px;
  }
  .wellcome .avatar-container span {
    display: none;
  }
  #profile-drop-zone {
    flex-direction: row;
  }

  div .myDropzone {
    margin: 0;
    margin-right: 5px;
    box-sizing: border-box;
  }

  #profile-drop-zone .btn-submit-avatar,
  #profile-drop-zone .btn-cancel-avatar {
    margin-bottom: 0;
  }
  #profile-drop-zone .btn-submit-avatar {
    margin-right: 5px;
  }
  .update-profile-container {
    align-items: center;
  }
}
@media (max-width: 840px) {
  .nav-bar-profile .information-profile {
    min-width: 90px;
    font-size: 16px;
    padding: 12px 10px;
  }
}
@media (max-width: 720px) {
  .update-profile-container {
    width: 500px;
    margin: 0 auto;
  }
  #form-contact-information,
  #form-contact-password {
    width: 100%;
  }
  .side-bar-profile {
    flex-direction: column;
  }
}
@media (max-width: 520px) {
  .side-bar-profile {
    flex-direction: row;
  }
  .side-bar-profile .wellcome,
  .side-bar-profile .user-hello,
  .side-bar-profile .nav-bar-profile {
    flex-direction: column;
  }
  .nav-bar-profile .information-profile {
    width: 90%;
  }
  .nav-bar-profile .information-profile:not(:last-child) {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .update-profile-container {
    min-width: 310px;
    width: 100%;
  }
  .profile-container h2 {
    font-size: 34px;
  }
  #form-contact-information .account-content {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .account-content .user-civility {
    margin-right: 0;
    align-items: center;
  }
  .account-content .user-civility .checkbox-signup {
    width: 190px;
    text-align: center;
  }
  #form-contact-information,
  #form-contact-password {
    padding: 10px;
  }
}
@media (max-width: 400px) {
  .side-bar-profile #profile-drop-zone {
    flex-direction: column;
    padding: 12px 5px;
  }
  #profile-drop-zone .btn-submit-avatar,
  #profile-drop-zone .btn-cancel-avatar {
    margin-top: 15px;
  }
}
