.track {
  width: 150px;
  height: 5px;
  border-radius: 5px;
  margin-left: 10px;
  position: relative;
}
.trackThumb {
  height: 15px;
  width: 15px;
  background-color: #85b4aa;
  border-radius: 50%;
  position: relative;
  outline: none;
}
.trackThumb:focus-visible {
  border: 1px solid #f0e1a9;
}
.trackThumb-price {
  position: absolute;
  top: 15px;
  font-size: 16px;
  outline: none;
}
