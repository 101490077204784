/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-ul::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.scroll-ul {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scroll-container {
  width: 200px;
  border: solid 2px #b1d9c1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  margin: none;
  flex-shrink: 0;
}
.scroll-btn-open,
.scroll-btn-close,
.scroll-li,
.scroll-ul,
.no-value {
  width: 100%;
  font-size: 18px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 20px;
}
.scroll-btn-open,
.scroll-btn-close {
  position: relative;
  cursor: pointer;
  font-weight: bold;
  box-shadow: none;
  text-align: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: #e4fdd9;
}
.scroll-btn-close {
  border-radius: 10px;
}
.scroll-ul {
  list-style: none;
  width: 200px;
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  background-color: #e4fdd9;
  left: -2px;
  border: solid 2px #b1d9c1;
  border-top: none;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
}
.scroll-li {
  border-bottom: solid 2px #b1d9c1;
}
.scroll-li:last-child {
  border-bottom: none;
  margin-bottom: -10px;
}
.scroll-li:first-child {
  border-top: solid 2px #b1d9c1;
  margin-top: -10px;
}
.scroll-li:hover {
  background-color: bisque;
}

.no-value {
  font-size: 18px;
  font-weight: bold;
}
.icon-scroll {
  margin-left: 15px;
  position: absolute;
  top: 6px;
  right: 20px;
  font-size: 26px;
}
.icon-scroll-hidden {
  display: none;
}
