/* Order */
.order-step-container {
  margin: 200px auto 0 auto;
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.order-step,
.order-step-active,
.order-step-active-first,
.order-step-active-last {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 20px;
}
.order-step-active,
.order-step-active-first,
.order-step-active-last {
  background-color: #b1d9c1;
}
.order-step-active::after,
.order-step-active-first::after,
.order-step-active::before,
.order-step-active-last::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: solid 50px transparent;
  border-bottom: solid 50px transparent;
}

.order-step-active::after,
.order-step-active-first::after {
  border-left: solid 50px #b1d9c1;
  right: -50px;
}
.order-step-active::before,
.order-step-active-last::before {
  border-left: solid 50px white;
  left: 0;
}

.order-container {
  max-width: 800px;
  margin: 50px auto 100px auto;
  padding: 15px;
  border: solid 2px #b1d9c1;
  border-radius: 15px;
}
.order-container h1 {
  text-align: center;
  margin-bottom: 20px;
}
.order-container h2 {
  text-align: end;
  margin-right: 20px;
}
.order-container .order-element {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.order-container .order-element .order-number span {
  margin: 0 5px;
}
.order-container .order-element .order-number svg {
  cursor: pointer;
}

.order-container .order-element .btn {
  background-color: transparent;
  box-shadow: none;
  color: #85b4aa;
}
.order-container .order-element .order-number svg:hover,
.order-container .order-element .btn:hover {
  color: #dfa06e;
}
.order-container .order-element div {
  min-width: 100px;
  margin: 10px 4px;
  display: flex;
  text-align: center;
  justify-content: center;
}
.order-container .order-element {
  border-bottom: solid 1px #b1d9c1;
}
.order-img {
  max-width: 100px;
  max-height: 70px;
}
.order-button,
.delivery-container,
.btn-payment,
.btn-adress-container,
.delivery-ordered,
.validation-ordered {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.adress-container,
.transport-container,
#order-form,
.order-payment,
.payment-method,
.payment-method div {
  display: flex;
  flex-direction: column;
}
.order-button {
  padding-top: 15px;
  border-top: solid 1px #b1d9c1;
}

/* Order form */
.adress-container .order-adress {
  padding: 10px;
  border-radius: 15px;
}
.adress-container .order-adress h3 {
  margin-top: 0;
}
.transport-container label,
.adress-container label {
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 22px;
}
.adress-container input {
  width: 300px;
  padding: 10px;
  border: solid 2px #b1d9c1;
  border-radius: 10px;
  margin-bottom: 10px;
  outline: none;
}
.adress-container input:focus {
  border-color: #dfa06e;
}
.adress-container .order-adress {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px;
}
.adress-container .order-adress div:nth-child(2),
.adress-container .order-adress div:nth-child(3),
.adress-container .order-adress div:nth-child(6) {
  font-weight: bold;
}

.transport-container input[type="radio"] {
  margin-right: 10px;
}
.transport-container div {
  padding: 0 35px;
  font-size: 20px;
}
.saved-adress:hover {
  background-color: #b1d9c1;
  cursor: pointer;
}
.order-form-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
  padding-top: 15px;
  border-top: solid 1px #b1d9c1;
}
/* order payment */
.order-payment {
  align-items: center;
  font-size: 20px;
}
.order-payment ul {
  list-style: disc;
  margin: 20px 15px;
  line-height: 22px;
}
.order-payment .payment-info > div span {
  font-weight: bold;
}
.order-payment li span {
  font-size: 14px;
  margin-left: 5px;
  font-style: italic;
}

.order-payment .payment-info div:nth-child(2),
.order-payment .payment-info div:nth-child(6),
.order-payment .payment-info div:nth-child(5) {
  margin: 15px 0;
}
.order-payment .payment-info div:nth-child(5) input {
  outline: none;
  border: solid 2px #85b4aa;
  border-radius: 5px;
  padding: 5px;
  margin: 0 10px;
}
.order-payment .payment-info div:nth-child(5) input:active,
.order-payment .payment-info div:nth-child(5) input:focus {
  border-color: #dfa06e;
}
.card-element {
  padding: 15px;
  border: solid 1px #b1d9c1;
  border-radius: 10px;
  min-width: 350px;
  margin-bottom: 15px;
}
/* validated */
.billing-container h2 {
  text-align: center;
  margin-bottom: 40px;
}
.billing-container .billing {
  margin-left: 80px;
}
.billing-container .delivery-container {
  justify-content: flex-end;
}
.billing-container .delivery-container .adress-delivery {
  margin-right: 80px;
}
.billing-container .adress-delivery,
.billing-container .billing {
  font-size: 20px;
}
.products-ordered {
  margin: 20px auto;
}
table {
  border-collapse: collapse;
  border-radius: 1em;
  overflow: hidden;
  outline: solid 1px #618f92;
}
.products-ordered th,
.products-ordered td {
  outline: 1px solid #618f92;
  padding: 10px;
  text-align: center;
}
.products-ordered thead th {
  background-color: #b1d9c1;
}
.delivery-ordered {
  justify-content: center;
}
.delivery-ordered p {
  font-size: 22px;
  margin-left: 10px;
  margin-top: 3px;
}
.payment-method {
  align-items: center;
}
.payment-method h3 {
  margin-top: 0;
}
.payment-method div {
  line-height: 20px;
  border: solid 1px #85b4aa;
  padding: 15px;
  border-radius: 1em;
}
.card-payment-info {
  margin-top: 15px;
  background-color: #b1d9c1;
}
.card-payment-info h4 {
  text-align: center;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: solid 1px #85b4aa;
}
.validation-ordered {
  margin-top: 25px;
  padding-top: 15px;
  border-top: solid 1px #85b4aa;
}
@media (max-width: 1090px) {
  .order-step-container {
    height: 80px;
    font-size: 16px;
  }
  .order-step-active::after,
  .order-step-active-first::after,
  .order-step-active::before,
  .order-step-active-last::before {
    border-top: solid 40px transparent;
    border-bottom: solid 40px transparent;
  }

  .order-step-active::after,
  .order-step-active-first::after {
    border-left: solid 40px #b1d9c1;
    right: -40px;
  }
  .order-step-active::before,
  .order-step-active-last::before {
    border-left: solid 40px white;
  }
}

@media (max-width: 830px) {
  .order-container .order-element div:nth-child(2),
  .order-container .order-element div:nth-child(5) {
    display: none;
  }
  .order-container h2 {
    font-size: 26px;
  }
}
@media (max-width: 590px) {
  .order-step-container {
    margin-top: 150px;
    height: 40px;
    font-size: 14px;
    line-height: 11px;
  }
  .order-step-active::after,
  .order-step-active-first::after,
  .order-step-active::before,
  .order-step-active-last::before {
    border-top: solid 20px transparent;
    border-bottom: solid 20px transparent;
  }

  .order-step-active::after,
  .order-step-active-first::after {
    border-left: solid 20px #b1d9c1;
    right: -20px;
  }
  .order-step-active::before,
  .order-step-active-last::before {
    border-left: solid 20px white;
  }
  .order-container {
    max-width: 800px;
    margin: 50px 10px 100px 10px;
    padding: 15px;
  }
  .order-container h1 {
    font-size: 36px;
  }

  .order-container h2 {
    font-size: 18px;
  }
  .order-container button,
  .order-container label,
  .order-container div,
  .order-container h3 {
    font-size: 16px;
  }
  .order-container .btn {
    padding: 5px;
  }

  .order-container p,
  .order-container span,
  .order-container .order-element svg {
    font-size: 14px;
  }
  .products-ordered td {
    font-size: 12px;
  }
  .order-container .order-element div {
    min-width: 30px;
  }

  .order-container .delivery-container {
    flex-direction: column-reverse;
  }
  .billing-container .delivery-container {
    flex-direction: row;
  }
  .adress-container input {
    width: 250px;
  }
  .adress-container .error {
    min-width: 250px;
    margin: 0;
  }
  .products-ordered {
    max-width: 350px;
  }
  .card-element {
    min-width: 220px;
  }
  .order-payment .payment-info div:nth-child(5) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .order-payment .payment-info div:nth-child(5) input {
    margin: 10px auto;
  }
}
